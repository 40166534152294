import React, { Component } from 'react';
import { Link } from "gatsby";
import client from "../../client"
import { urlFor } from '../../utils/image-builder'
// import { Select } from 'antd';
import Select from 'react-select'


import GridItem from '../GridItem/GridItem'
import Header from '../Header/Header'

import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

import OriginalContentHeaderImg from '../../assets/reels.jpg'
import Button from '../Button/Button'


// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';

// import 'antd/lib/select/style/css'
import './OriginalContents.css'
const { Option } = Select;



export default class OriginalContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            titles: [],
            categories: [],
            genres: [],
            developmentStages: [],
            currentCategory: null,
            currentGenre: null,
            currentDevStage: null,
            isLoading: true
        };


    }

    componentDidMount() {
        this.getTitles();
        this.getOriginalContentCategories();
        this.getGenres();
        this.getDevelopmentStages();
    }
        

    getTitles = () => {
        let category = this.state.currentCategory;
        let genre = this.state.currentGenre;
        let stage = this.state.currentDevStage;

        let queryHead = '_type == "originalContent"';

        if(category !== null) {
            queryHead += `&& category._ref == "${category}"`;
        }

        if(genre !== null) {
            queryHead += `&& "${genre}" in genre[]->_id`;
        }

        if(stage !== null) {
            queryHead += `&& developmentStage._ref == "${stage}"`;
        }


        client.fetch(`*[${queryHead}] | order(order asc) 
        {_id, title, slug, mainImage, category, textPreview}`).then(data => { 
                this.setState({titles: data})
            })
            .then(() => this.setState({isLoading: false}))
            .catch(err => console.log(err))
    }

    /**
     * Fetch original content categories from Sanity data store
     * @see {@link https://www.sanity.io/docs/js-client} for more information
     */
    getOriginalContentCategories = () => {
        client.fetch(`*[_type == "originalContentCategory"]`)
        .then(categories => {
            let options = [{'value': null, 'label': 'All'}];
            categories.forEach(category => {
                options.push({'value': category._id, 'label': category.title})
            });
            this.setState({categories: options})
        })
        .catch(err => console.log(err))
    }

    /**
     * Fetch genres from Sanity data store
     * @see {@link https://www.sanity.io/docs/js-client} for more information
     */
     getGenres = () => {
        client.fetch(`*[_type == "genre"] | order(title asc)`)
        .then(genres => {
            let options = [{'value': null, 'label': 'All'}];
            genres.forEach(genre => {
                options.push({'value': genre._id, 'label': genre.title})
            });
            this.setState({genres: options})
        })
        .catch(err => console.log(err))
    }


    /**
     * Fetch original content development stage from Sanity data store
     * @see {@link https://www.sanity.io/docs/js-client} for more information
     */
     getDevelopmentStages = () => {
        client.fetch(`*[_type == "developmentStages"] | order(order asc)`)
        .then(stages => {
            let options = [{'value': null, 'label': 'All'}];
            stages.forEach(stage => {
                options.push({'value': stage._id, 'label': stage.title})
            });
            this.setState({developmentStages: options})
        })
        .catch(err => console.log(err))
    }

    changeCategory = (category) => {
        console.log(category);
        let val = null;
        if(category.value !== null) {
            val = `${category.value}`
        }
        this.setState({currentCategory: val}, () => this.getTitles())
    }

    changeGenre = (genre) => {
        let val = null;
        if(genre.value !== null) {
            val = `${genre.value}`
        }
        this.setState({currentGenre: val}, () => this.getTitles())
    }

    changeDevelopmentStages = (devStages) => {
        let val = null;
        if(devStages.value !== null) {
            val = `${devStages.value}`
        }
        this.setState({currentDevStage: val}, () => this.getTitles())
    }


    render() {
        let fetchedTitles = this.state.titles
        let loaderStyle = {
            // position: 'absolute',
            display: 'block',
            width: '100%',
            height: '100vh',
            padding: '10%',
            margin: 'auto',
            backgroundColor: 'rgba(255,255,255,0.95)',
            zIndex: '999',
            textAlign: 'center',
            letterSpacing: '0.05em',
            color: '#52557a'
        }

        if(!this.state.isLoading) {
            loaderStyle = {display: 'none'}
        }

        const customStyles = {
            option: (provided, state) => ({
              ...provided,
              color: state.isSelected ? 'white' : 'black',
              padding: 10,
            }),
            control: (provided) => ({
              // none of react-select's styles are passed to <Control />
              ...provided,
              backgroundColor: 'black'
            }),
            
            singleValue: (provided, state) => {
              const opacity = state.isDisabled ? 0.5 : 1;
              const transition = 'opacity 300ms';
          
              return { ...provided, opacity, transition, color: 'white' };
            },
            indicatorSeparator: () => ({
                display: 'none'
            })
          }
        return(
            <div>
                <Header 
                    pageName="Original Content" 
                    bgColor="rgba(0,0,0,0.85)" 
                    bgImage={OriginalContentHeaderImg}
                    title="Original Content"
                    content={<><br />
                        <p> 
                            At Sleigh Group, we create and develop human stories that cross cultural boundaries. 
                            We value diversity, own voices and are always looking for new storytellers with unique viewpoints. 
                            If you have any questions about our current projects, please contact us. 
                        </p>
                       
                            <a href={"mailto: originalcontent@sleigh.group?subject=Original Content Inquiry"} style={{marginRight: 10}}>
                        <Button buttonClass={"simple-button"} buttonText={"Contact us"}></Button>
                        </a>
                        
                    </>}
                    flexDirection="row"
                    marginTop="70px"
                    paddingBottom="40px"
                    align="center"
                />
                <div>
                
                    <div className="filter-container">

                        <div className="original-content-categories">
                            {this.state.categories.map((category, i) => {
                                return (
                                    <div 
                                    key={category.value}
                                    style={this.state.currentCategory === category.value ? 
                                        {backgroundColor: 'white', color: 'black'} : null}
                                    onClick={() => this.changeCategory(category)}>
                                        {category.label} 
                                    </div>
                                );
                            })}
                        </div>

                        <div className="original-content-filters">
                            <div className="dropdown-filter-container">
                                <label>Genre</label>
                                <Select options={this.state.genres} onChange={this.changeGenre} styles={customStyles}/>
                            </div>
                            <div className="dropdown-filter-container">
                            <label>Development Stage</label>
                                <Select options={this.state.developmentStages} onChange={this.changeDevelopmentStages} styles={customStyles} />
                            </div>
                            
                        </div>
                    </div>


                    <div className="slate">

                                                    
                        {this.state.isLoading ? <div className="loader" style={loaderStyle}><h3>Loading...</h3></div> :
                        fetchedTitles.length > 0 ?
                        fetchedTitles.map((title) => {
                            let textPreview = '';
                            if(title.textPreview != null) {
                                textPreview = title.textPreview;
                            }

                            return(
                                // <ScrollAnimation animateIn="fadeIn" duration={1} animateOnce>
                                    <div className="slate-item" key={title._id}>
                                        <Link 
                                            to={`/original-content/${title.slug.current}`} 
                                            state={{
                                                slug: title.slug.current
                                            }}>
                                            <GridItem 
                                            title={title.title}
                                            image={urlFor(title.mainImage.asset._ref)}
                                            bgColor="rgba(0,0,0,0.4)"
                                            content={textPreview}
                                            textClass="with-animation"
                                            /> 
                                        {/* <p>{title.title}</p> */}
                                        </Link>
                                    </div>
                                // </ScrollAnimation>
                                );
                        }) : <div style={{color: 'white', padding: '10%', height: '70vh'}}><h2>No matching results</h2></div>}
                    </div>
                </div>
            </div>
        )

    }
}