import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import OriginalContents from "../components/OriginalContents/OriginalContents"

const OriginalContentPage = (props) => (
  <Layout >
    <SEO 
      title="Original Content" 
      keywords="translation, localisation, localization, English to Tagalog, Tagalog to English, cultural translation, cultural naturalization, cultural naturalisation, production management, voice acting, voice actors, voice actor management, dubbing, voice over, Australia, Philippines, Sleigh Platform, subtitles, subtitling, language services, news"/>
    <OriginalContents location={props.location} />
  </Layout>
)

export default OriginalContentPage
